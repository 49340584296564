$switch-transition-duration: 0.25s;
$switch-transition-timing-function: ease;

$switch-label-width: 48px;
$switch-label-height: 24px;

$switch-rail-width: 100%;
$switch-rail-height: 100%;
$switch-rail-background-color: #eeeeee;
$switch-rail-border-radius: 9999em;
$switch-rail-checked-background-color: #eeeeee;

$switch-knob-width: 24px;
$switch-knob-height: 24px;
$switch-knob-border-radius: 50%;
$switch-knob-background-color: $color-grey-800;
$switch-knob-box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
$switch-knob-checked-left: $switch-knob-width;
$switch-knob-checked-background-color: $color-blue;

.c-checkbox-switcher-wrap {

}

.c-checkbox-switcher-text {
  margin-left: 10px;
}

/* Switch Container */
.c-checkbox-switcher {
  position: relative;
  display: inline-block;
}

/* Hide the checkbox input (only needed for `:checked` property) */
.c-checkbox-switcher-input {
  display: none;
}

.ant-form label.c-checkbox-switcher {
  font-size: 16px;
}

/* Switch */
.c-checkbox-switcher {
  .ant-checkbox {
    //display: block;
    width: $switch-label-width;
    height: $switch-label-height;
    text-indent: -150%;
    clip: rect(0 0 0 0);
    color: transparent;
    user-select: none;

    & + span {
      color: $color-grey-700;
    }

    .ant-checkbox-inner {
      width: 0;
      height: 0;
      opacity: 0;
    }

    /* Switch Rail & Knob */
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      cursor: pointer;
    }

    /* Switch Rail */
    &:before {
      width: $switch-rail-width;
      height: $switch-rail-height;
      background-color: $switch-rail-background-color;
      border-radius: $switch-rail-border-radius;
      transition: background-color $switch-transition-duration $switch-transition-timing-function;
    }

    /* Switch Knob */
    &:after {
      top: 0;
      left: 0;
      visibility: visible;
      border:  0;
      width: $switch-knob-width;
      height: $switch-knob-height;
      border-radius: $switch-knob-border-radius;
      background-color: $switch-knob-background-color;
      box-shadow: $switch-knob-box-shadow;
      transition: left $switch-transition-duration $switch-transition-timing-function;
    }
  }
}


/* When input is checked */
.c-checkbox-switcher.ant-checkbox-wrapper-checked .ant-checkbox {
  & + span {
    color: $color-grey;
  }

  &:before {
    background-color: $switch-rail-checked-background-color;
  }

  &:after {
    left: $switch-knob-checked-left;
    background-color: $switch-knob-checked-background-color;
  }
}