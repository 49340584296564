@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icons/icomoon.eot') format('embedded-opentype'),
  url('../../fonts/icons/icomoon.ttf') format('truetype'),
  url('../../fonts/icons/icomoon.woff') format('woff'),
  url('../../fonts/icons/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}