%flex {
  display: -ms-flexbox;
  display: flex;
}

%align-middle {
  -ms-flex-align: center;
  align-items: center;
}

%align-top {
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

%align-bottom {
  -ms-flex-align: flex-end;
  align-items: flex-end;
}

%align-stretch {
  -ms-flex-align: stretch;
  align-items: stretch;
}

%align-between {
  -ms-flex-pack: space-between;
  justify-content: space-between;
}

%align-around {
  -ms-flex-pack: space-around;
  justify-content: space-around;
}

%align-center {
  -ms-flex-pack: center;
  justify-content: center;
}

%align-left {
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
}

%align-right {
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
}

%justify-stretch {
  -ms-flex-pack: stretch;
  justify-content: stretch;
}

%primary-transition {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

%user-cursor {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

%not-selectible {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

%inline-middle {
  display:inline-block;
  vertical-align: middle;
}

%hidden {
  visibility: hidden;
  opacity:0;
}

%show {
  visibility: visible;
  opacity: 1;
}

%wrapped {
  @include transition(max-height, 0.5s, ease);
  max-height: 0;
  overflow: hidden;
}

%slide {
  @include transition(max-height, 1s, ease);
  max-height: 500px;
}

%text-clamb-4 {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 100px;
}

%clip-overflow {
  overflow: hidden;
  -webkit-overflow-scrolling: hidden;
}

%text-background {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

%dash-x {
  background: url(../../img/lines-x.png) no-repeat;
  background-size: auto;
}

%dash-x-dark {
  background: url(../../img/lines-x-dark.png) no-repeat;
  background-size: auto;
}

%dash-top {
  background-position: top;
}

%dash-bottom {
  background-position: bottom;
}

%dash-left {
  background-position: left;
}

%dash-right {
  background-position: right;
}

%text-clamb-2 {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 44px;
}

%hide {
  visibility: hidden;
  opacity: 0;
}

%show {
  visibility: visible;
  opacity: 1;
}