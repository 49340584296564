@import "../utils/_variables";

.row {
  max-width: $rowWidth;
  margin-right: auto;
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.row .row {
  margin-right: -$columnSpace;
  margin-left: -$columnSpace;
}

.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}

.row.expanded {
  max-width: none;
}

.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}

.row:not(.expanded) .row {
  max-width: none;
}

.column, .columns {
  -ms-flex: 1 1 0px;
  flex: 1 1 0px;
  padding-right: $columnSpace;
  padding-left: $columnSpace;
}

.column.row,
.columns.row {
  display: block;
}

.row .column.row,
.row .row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

@each $type in $gridBreakpoints {
  @media print, screen and (min-width: #{nth($type, 2)}) {
    #{nth($type, 1)}-row {
      max-width: $rowWidth;
      margin-right: auto;
      margin-left: auto;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }

    @for $i from 1 through $columnsSize {
      .#{nth($type, 1)}-#{$i} {
        -ms-flex: 0 0 calc(100% / $columnsSize * $i);
        flex: 0 0 calc(100% / $columnsSize * $i);
        max-width: calc(100% / $columnsSize * $i);
      }
    }

    .#{nth($type, 1)}-up-1 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .#{nth($type, 1)}-up-1 > .column, .#{nth($type, 1)}-up-1 > .columns {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .#{nth($type, 1)}-up-2 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .#{nth($type, 1)}-up-2 > .column, .#{nth($type, 1)}-up-2 > .columns {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }

    .#{nth($type, 1)}-up-3 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .#{nth($type, 1)}-up-3 > .column, .#{nth($type, 1)}-up-3 > .columns {
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }

    .#{nth($type, 1)}-up-4 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .#{nth($type, 1)}-up-4 > .column, .#{nth($type, 1)}-up-4 > .columns {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }

    .#{nth($type, 1)}-up-5 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .#{nth($type, 1)}-up-5 > .column, .#{nth($type, 1)}-up-5 > .columns {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }

    .#{nth($type, 1)}-up-6 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .#{nth($type, 1)}-up-6 > .column, .#{nth($type, 1)}-up-6 > .columns {
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }

    .#{nth($type, 1)}-up-7 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .#{nth($type, 1)}-up-7 > .column, .#{nth($type, 1)}-up-7 > .columns {
      -ms-flex: 0 0 14.28571%;
      flex: 0 0 14.28571%;
      max-width: 14.28571%;
    }

    .#{nth($type, 1)}-up-8 {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .#{nth($type, 1)}-up-8 > .column, .#{nth($type, 1)}-up-8 > .columns {
      -ms-flex: 0 0 12.5%;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
  }
}

.shrink {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
}

.align-left {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.align-right {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.align-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.align-justify {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.align-spaced {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.align-top {
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-self-top {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.align-bottom {
  -ms-flex-align: end;
  align-items: flex-end;
}

.align-self-bottom {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.align-middle {
  -ms-flex-align: center;
  align-items: center;
}

.align-self-middle {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.align-stretch {
  -ms-flex-align: stretch;
  align-items: stretch;
}

.align-self-stretch {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.align-center-middle {
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

@each $type in $gridBreakpoints {
  @media print, screen and (min-width: #{nth($type, 2)}) {
    .#{nth($type, 1)}-shrink {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      max-width: 100%;
    }

    .#{nth($type, 1)}-align-left {
      -ms-flex-pack: start;
      justify-content: flex-start;
    }

    .#{nth($type, 1)}-align-right {
      -ms-flex-pack: end;
      justify-content: flex-end;
    }

    .#{nth($type, 1)}-align-center {
      -ms-flex-pack: center;
      justify-content: center;
    }

    .#{nth($type, 1)}-align-justify {
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    .#{nth($type, 1)}-align-spaced {
      -ms-flex-pack: distribute;
      justify-content: space-around;
    }

    .#{nth($type, 1)}-align-top {
      -ms-flex-align: start;
      align-items: flex-start;
    }

    .#{nth($type, 1)}-align-self-top {
      -ms-flex-item-align: start;
      align-self: flex-start;
    }

    .#{nth($type, 1)}-align-bottom {
      -ms-flex-align: end;
      align-items: flex-end;
    }

    .#{nth($type, 1)}-align-self-bottom {
      -ms-flex-item-align: end;
      align-self: flex-end;
    }

    .#{nth($type, 1)}-align-middle {
      -ms-flex-align: center;
      align-items: center;
    }

    .#{nth($type, 1)}-align-self-middle {
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }

    .#{nth($type, 1)}-align-stretch {
      -ms-flex-align: stretch;
      align-items: stretch;
    }

    .#{nth($type, 1)}-align-self-stretch {
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
    }

    .#{nth($type, 1)}-align-center-middle {
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-line-pack: center;
      align-content: center;
    }

    .#{nth($type, 1)}-order-1 {
      -ms-flex-order: 1;
      order: 1;
    }

    .#{nth($type, 1)}-order-2 {
      -ms-flex-order: 2;
      order: 2;
    }

    .#{nth($type, 1)}-order-3 {
      -ms-flex-order: 3;
      order: 3;
    }

    .#{nth($type, 1)}-order-4 {
      -ms-flex-order: 4;
      order: 4;
    }

    .#{nth($type, 1)}-order-5 {
      -ms-flex-order: 5;
      order: 5;
    }

    .#{nth($type, 1)}-order-6 {
      -ms-flex-order: 6;
      order: 6;
    }
  }
}

.flex-container {
  display: -ms-flexbox;
  display: flex;
}

.flex-child-auto {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.flex-child-grow {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.flex-child-shrink {
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.flex-dir-row {
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-dir-row-reverse {
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.flex-dir-column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-dir-column-reverse {
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

@each $type in $gridBreakpoints {
  @media print, screen and (min-width: #{nth($type, 2)}) {
    .#{nth($type, 1)}-flex-container {
      display: -ms-flexbox;
      display: flex;
    }

    .#{nth($type, 1)}-flex-child-auto {
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
    }

    .#{nth($type, 1)}-flex-child-grow {
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
    }

    .#{nth($type, 1)}-flex-child-shrink {
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
    }

    .#{nth($type, 1)}-flex-dir-row {
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .#{nth($type, 1)}-flex-dir-row-reverse {
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
    }

    .#{nth($type, 1)}-flex-dir-column {
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .#{nth($type, 1)}-flex-dir-column-reverse {
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
    }
  }
}

@media (max-width: $media-xxxl) {
  .row {
    max-width: 1160px;
  }
}

@media (max-width: 1440px) {
  .row {
    max-width: 1067px;
  }
}

@media (max-width: $media-xl) {
  .row {
    max-width: 977px;
  }
}