$color-blue: #3665C1;
$color-blue-2: #8F9CA4;
$color-red: #FF4D4F;
$color-lightred: #FF551F;
$color-green: #36C1A3;
$color-yellow: #F5C65B;
$color-orange: #FF551F;

$color-grey: #3D3D3D;
$color-grey-900: #868788;
$color-grey-800: #8F9CA4;
$color-grey-700: #C6C6C6;
$color-grey-600: #D9D9D9;
$color-grey-500: #E5E5E5;
$color-grey-400: #E7EDF2;
$color-grey-300: #EEEEEE;
$color-grey-200: #F4F6F8;
$color-grey-100: #F8F8F8;

$shadow-layout: 0px 8px 40px rgba(215, 224, 233, 0.4);
$shadow-layout-active: 0px 8px 60px rgba(215, 224, 233, 0.8);
$shadow-sidebar: 0px 8px 40px #D7E0E9;
$shadow-tag: 0px 2px 15px rgba(134, 134, 134, 0.2);

$backdrop-blur: blur(50px);

$fontPoppins: 'Poppins';
$baseFontFamily: $fontPoppins, Arial, sans-serif;

$media-xxxl: 1600px;
$media-xxl: 1366px;
$media-xl: 1280px;
$media-lg: 1200px;
$media-md: 768px;
$media-sm: 767px;

$gridBreakpoints: (
        'small' 0,
        'medium' $media-md,
        'large' $media-lg,
        'x-large' $media-xl,
        'xx-large' $media-xxl,
        'xxx-large' $media-xxxl
);

$rowWidth: 1450px;
$columnsSize: 12;
$columnSpace: 16px;

$mediaMedium: 40em;
$mediaMediumLarge: 1201px;
//$mediaLarge: 1367px;
$mediaLarge: 64em;

//$accountLeft: 16.3%;
$accountLeft: 312px;
//$accountRight: 83.7%;
$accountRight: calc(100% - 312px);