.custom-headline {
  h1, h2, h3, h4, h5, h6 {
    margin: initial;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }
}

$text-sizes: ( 10, 12, 11, 13, 14, 15, 16, 18, 20, 22, 24, 28, 34 );

$line-height-sizes: ( 13, 16, 17, 18, 20, 22, 24,  27, 30, 33, 34, 40, 48 );

.text {
  @include font(normal, 16, 1, 0, 0);

  @each $type in $text-sizes {
    &-#{nth($type, 1)} {
      font-size: #{nth($type, 1)}px;
    }
  }
}

.text-height {
  @each $type in $line-height-sizes {
    &-#{nth($type, 1)} {
      line-height: #{nth($type, 1)}px;
    }
  }
}

.font-black {
  font-weight: 900;
}

.font-heavy {
  font-weight: 800;
}

.font-bold, b, strong {
  font-weight: bold;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: normal;
}

.font-light {
  font-weight: 300;
}

.font-extralight {
  font-weight: 200;
}

.font-thin {
  font-weight: 200;
}

.font-spacing {
  &-02 {
    letter-spacing: 0.02em;
  }

  &-04 {
    letter-spacing: 0.04em;
  }
}

.font-uppercase {
  text-transform: uppercase;
}

.font-capitalize {
  text-transform: capitalize;
}

.font-italic {
  font-style: italic;
}

.text-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1440px) {
  .text {
    &-22 {
      font-size: 20px;
    }

    &-28 {
      font-size: 24px;
    }

    &-34 {
      font-size: 32px;
    }
  }
}

@media (max-width: $media-xxl) {
  .text {
    &-28 {
      font-size: 20px;
    }
  }
}

@media (max-width: $media-xl) {
  .text {
    &-22 {
      font-size: 18px;
    }
  }
}

@media (max-width: $media-sm) {
  .text {
    &-20 {
      font-size: 17px;
    }

    &-22 {
      font-size: 15px;
    }

    &-28 {
      font-size: 24px;
    }

    &-34 {
      font-size: 26px;
    }
  }
}