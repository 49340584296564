.not-found-wrapper {
  width: 20%;
  position: absolute;
  top: 40%;
  left: 40%;
  text-align: center;
}

@media screen and (max-width: 768px){
  .not-found-wrapper {
    width: 50%;
    left: 30%;
  }
}