@import '../utils/_module.scss';

$border-radiuses: ( 4, 5, 6, 8, 10);

.mt_10 {
  margin-top: 10px;
}

.mt_15 {
  margin-top: 15px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_40 {
  margin-top: 40px;
}

.mt_55 {
  margin-top: 55px;
}

.mt_60 {
  margin-top: 60px;
}

.mt_70 {
  margin-top: 70px;
}

.mt_80 {
  margin-top: 80px;
}

.mb_65 {
  margin-bottom: 65px;
}

.mb_60 {
  margin-bottom: 60px;
}

.mb_55 {
  margin-bottom: 55px;
}

.mb_50 {
  margin-bottom: 50px;
}

.mb_45 {
  margin-bottom: 45px;
}

.mb_40 {
  margin-bottom: 40px;
}

.mb_35 {
  margin-bottom: 35px;
}

.mb_30 {
  margin-bottom: 30px;
}

.mb_25 {
  margin-bottom: 25px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_0 {
  margin-bottom: 0;
}

.mt_0 {
  margin-top: 0;
}

.m_0 {
  margin: 0;
}

.pt_5 {
  padding-top: 5px;
}

.pt_10 {
  padding-top: 10px;
}

.pt_0 {
  padding-top: 0;
}

.pb_0 {
  padding-bottom: 0;
}

.p_0 {
  padding: 0;
}

.fluid-x {
  width: 100%;
}

.m-fluid-x {
  max-width: 100%;
}

.fluid-y {
  height: 100%;
}

.m-fluid-y-vh {
  min-height: 100vh;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.row-expanded {
  margin-left: -15px;
  margin-right: -15px;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.no-decor {
  text-decoration: none;
}

.disabled {
  pointer-events: none;
  user-select: none;
}

.nowrap {
  white-space: nowrap;
}

.bg {
  &-white {
    background-color: white;
  }

  &-lightblue {
  background-color: rgba(54, 101, 193, 0.1);
  }

  &-grey {
    background-color: #F4F6F8;
  }

  &-green {
    background-color: $color-green;
  }

  &-red {
    background-color: $color-red;
  }

  &-orange {
    background-color: $color-orange;
  }
}

.color {
  &-red {
    color: $color-red;
  }

  &-grey {
    color: $color-grey;

    &-100 {
      color: $color-grey-100;
    }

    &-200 {
      color: $color-grey-200;
    }

    &-300 {
      color: $color-grey-300;
    }

    &-400 {
      color: $color-grey-400;
    }

    &-500 {
      color: $color-grey-500;
    }

    &-600 {
      color: $color-grey-600;
    }

    &-700 {
      color: $color-grey-700;
    }

    &-800 {
      color: $color-grey-800;
    }

    &-900 {
      color: $color-grey-900;
    }
  }

  &-white {
    color: white;

    &-08 {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  &-black {
    color: black;
  }

  &-blue {
    color: $color-blue;

    &-2 {
      color: $color-blue-2;
    }
  }

  &-yellow {
    color: $color-yellow;
  }

  &-green {
    color: $color-green;
  }

  &-inherit {
    color: inherit;
  }
}

//.color-gradient {
//  -webkit-background-clip: text;
//  background-clip: text;
//  -webkit-text-fill-color: transparent;
//}
//
//@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
//  .color-gradient {
//    background: transparent !important;
//    background-image: inherit !important;
//    color: $color-green;
//  }
//
//  .color-green--gradient {
//    color: $color-green;
//  }
//}

.radius {
  @each $type in $border-radiuses {
    &-#{nth($type, 1)} {
      border-radius: #{nth($type,1)}px;
    }
  }

  &-circle {
    border-radius: 50%;
  }
}

.shadow {
  &-layout {
    box-shadow: $shadow-layout;
  }

  &-layout-active {
    box-shadow: $shadow-layout-active;
  }

  &-tag {
    box-shadow: $shadow-tag;
  }
}

.display-block {
  display: block;
}

.inline-middle {
  display: inline-block;
  vertical-align: middle;
}

.width-inherit {
  width: inherit;
}

.hide {
  display: none;
}

.cursor {
  cursor: pointer;
}

.clip {
  overflow: hidden;
}

.visible-first {
  position: relative;
  z-index: 1;
}

@media (min-width: $media-lg) {
  .hide-lg {
    display: none;
  }

  .show-lg {
    display: block;

    &.flex-container, &.large-flex-container {
      display: flex;
    }
  }
}

@media (max-width: $media-lg) {
  .hide-md {
    display: none;
  }

  .show-md {
    display: block;

    &.flex-container, &.medium-flex-container {
      display: flex;
    }
  }
}

@media (max-width: 767px) {
  .hide-sm {
    display: none;
  }

  .show-sm {
    display: block;

    &.flex-container {
      display: flex;
    }
  }

  .sm-text-center {
    text-align: center;
  }
}