@import "../utils/variables";

$fonts:  (
  //      fontName    fontType   fontWeight  fontStyle
        $fontPoppins 'Black' 900 normal,
        $fontPoppins 'BlackItalic' 900 italic,
        $fontPoppins 'ExtraBold' 800 normal,
        $fontPoppins 'ExtraBoldItalic' 800 italic,
        $fontPoppins 'Bold' bold normal,
        $fontPoppins 'BoldItalic' bold italic,
        $fontPoppins 'SemiBold' 600 normal,
        $fontPoppins 'SemiBoldItalic' 600 italic,
        $fontPoppins 'Medium' 500 normal,
        $fontPoppins 'MediumItalic' 500 italic,
        $fontPoppins 'Regular' normal normal,
        $fontPoppins 'Italic' normal italic,
        $fontPoppins 'Light' 300 normal,
        $fontPoppins 'LightItalic' 300 italic,
        $fontPoppins 'ExtraLight' 200 normal,
        $fontPoppins 'ExtraLightItalic' 200 italic,
        $fontPoppins 'Thin' 100 normal,
        $fontPoppins 'ThinItalic' 100 italic,
);

@each $type in $fonts {
  @font-face {
    font-family: '#{nth($type, 1)}';
    src: local('#{nth($type, 1)} #{nth($type, 2)}'), local('#{nth($type, 1)}-#{nth($type, 2)}'),
    url('../../fonts/#{nth($type, 1)}-#{nth($type, 2)}.woff2') format('woff2'),
    url('../../fonts/#{nth($type, 1)}-#{nth($type, 2)}.woff') format('woff');
    font-weight: #{nth($type, 3)};
    font-style: #{nth($type, 4)};
    font-display: swap;
  }
}