@import '~antd/dist/antd.min.css';

.form-item {
  margin-bottom: 24px;

  &-label {
    margin-bottom: 3px;
  }

  &-hint {
    margin-top: 5px;
  }

  &.lg {
    .ant-input, .ant-input-number-input {
      height: 56px;
    }

    .input-number-suffix-wrapper {
      top: 19px;
    }

    .ant-checkbox + span {
      padding: 0 12px;
    }

    .ant-checkbox-inner {
      width: 22px;
      height: 22px;

      &:after {
        left: 30%;
      }
    }
  }

    &-sm {
        .ant-select-selection--single {
            height: 32px;
        }

        .ant-select-selection__rendered {
            line-height: 32px;
        }

        .ant-select-arrow:after {
            font-size: 26px;
            top: -6px;
        }
    }

    &-white {
        .ant-input {
            background-color: white;
            height: 56px;
        }

    .ant-select-selection {
      background-color: white;
      border-color: #eeeeee;
    }

    .ant-select-selection--multiple {
      min-height: 56px;
    }
  }

  &-bordered {
    .ant-input,
    .ant-input-affix-wrapper,
    .ant-select-selection {
      background-color: transparent;
      border-color: #eeeeee;
    }
  }

  &-separator {
    color: $color-grey-800;
    font-size: 25px;
    padding: 0 9px;
  }

  &-entity {
    .ant-radio-wrapper {
      margin-top: 10px;
    }
  }
}

.form_card {
  padding: 60px 60px 50px;
}

.ant-select-selection--multiple {
  position: relative;

  ul {
    display: flex;
    flex-flow: row wrap;
  }

  .ant-select-selection__rendered {
    padding: 11px 14px 14px;
    line-height: inherit;
    display: flex;
  }

  &:after {
    content: "\e928";
    font-family: 'icomoon' !important;
    display: block;
    font-size: 26px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: $color-grey;
  }
}

.ant-select-selection--multiple .ant-select-arrow, .ant-select-selection--multiple .ant-select-selection__clear {
  top: 50%;
  right: 45px;
}

.ant-select-selection--multiple .ant-select-selection__rendered > ul > li, .ant-select-selection--multiple > ul > li {
  min-height: 28px;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
}

.ant-select-selection--multiple .ant-select-selection__choice {
  background-color: #EEEEEE;
  margin-right: 8px;
}

.ant-input,
.ant-input-number,
.ant-input-affix-wrapper,
.ant-select-selection {
  background-color: $color-grey-200;
  border: 1px solid $color-grey-400;
  @extend .radius-4;
}

.ant-input-number {
  height: inherit;
}

.unborder {
  input {
    border: 0;
  }
}

.bids_table {
  &.c-table-bordered table {
    width: 120%;
  }
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 40px;
}

.ant-input,
.ant-input-number-input {
  height: 48px;
  @extend .font-medium;
  color: $color-grey;
  padding: 4px 16px;

  &.lg {
    height: 56px;
  }
}

.ant-select-selection-item {
  @extend .font-medium;
  color: $color-grey;
}

.ant-input-affix-wrapper {
  //padding: 0 16px;
}

.ant-select-selection--single,
.ant-select-auto-complete.ant-select .ant-select-selection__rendered,
.ant-select-auto-complete.ant-select .ant-input {
    height: 48px;
}
.ant-select-selection__rendered,
.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
    line-height: 48px;
}
.ant-select-selection__rendered,
.ant-select-selection-selected-value {
    height: 100%;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 46px;
}

.ant-select-arrow {
  &:after {
    content: "\e928";
    font-family: 'icomoon' !important;
    display: block;
    font-size: 33px;
    position: absolute;
    top: -10px;
    right:0;
    color: $color-grey;
  }

  .anticon {
    display: none;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: rgba($color-blue, .1);
}

.ant-input:hover,
  //.ant-input-affix-wrapper:hover,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled),
.ant-select-auto-complete.ant-select .ant-input:hover {
    border-color: $color-blue;
}

.ant-input-focused, .ant-input:focus,
//.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus,
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-input-number-focused, .ant-input-number:hover {
    border-color: $color-blue;
    -webkit-box-shadow: 0 0 0 2px rgba($color-blue, 0.1);
    box-shadow: 0 0 0 2px rgba($color-blue, 0.1);
}

.ant-checkbox-wrapper {
  @extend .text;
  @extend .text-14;
  @extend .text-height-24;
  @extend .color-grey;
}

.ant-checkbox-inner {
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $color-blue;
  border-color: $color-blue;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after,
.ant-checkbox-checked .ant-checkbox-inner:after {
  display: block;
}

.c-table .ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner:after {
  left: 23%;
}

.table-row-cursor {
  .ant-table-row {
    cursor: pointer;
  }
}

.ant-table-body {
  overflow: auto;
}

.ant-radio {
  top: 1px;
}

.ant-radio-inner {
  background-color: $color-grey-200;
  width: 20px;
  height: 20px;

  &:after {
    background-color: $color-blue;
    width: 12px;
    height: 12px;
  }
}

.ant-radio-checked .ant-radio-inner {
  border-color: $color-blue;
  background-color: white;
}

.ant-radio-group {
  @extend .text;
  @extend .color-grey;
}

.ant-slider-rail {
  background-color: #EEEEEE;
  @extend .radius-5;
}

.ant-slider-track,
.ant-slider:hover .ant-slider-track{
  background-color: $color-blue;
}

.ant-slider-handle {
  width: 16px;
  height: 16px;
  margin-top: -7px;
  border: 3px solid $color-blue;
}

.ant-slider-handle.ant-tooltip-open {
  border-color: $color-blue;
}

.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging, .ant-slider-handle:focus {
  -webkit-box-shadow: 0 0 0 5px rgba($color-blue, 0.1);
  box-shadow: 0 0 0 5px rgba($color-blue, 0.1);
  border-color: $color-blue;
}

.ant-slider-tooltip {
  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    background-color: inherit;
    box-shadow: none;
    min-height: inherit;
    color: $color-blue;
    padding: 0;
    @extend .text;
    @extend .text-12;
    @extend .font-heavy;
  }
}

.ant-radio-group-outline {
  display: block;
}

.ant-radio-wrapper {
  margin: 0 23px 0 0;
}

.radio-template {
  @extend .relative;
  max-width: 280px;

  > .ant-radio-wrapper {
    transition: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    background-color: $color-grey-200;
    @extend .radius-4;
    @extend .fluid-x;
    padding: 14px 18px;
    min-height: 48px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid $color-blue;
      border-radius: inherit;
      transition: cubic-bezier(0.78, 0.14, 0.15, 0.86);
      @extend %hide;
    }
  }

  > .ant-radio-wrapper-checked {
    &:before {
      @extend %show;
    }

    + .icon {
      color: $color-blue;
    }
  }

  .pined-icon {
    transition: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 23px;
  }
}

.range_slider_wrap {
  margin-top: 16px;
}

//tab
.tab_default {
  .ant-tabs-bar {
    border-bottom: 2px solid rgba($color-grey-800, .2);
    margin-bottom: 32px;
  }

  .ant-tabs-nav .ant-tabs-tab {
    padding: 15px 0;
    color: $color-grey-800;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: $color-blue;

    .tab_badge {
      background-color: rgba($color-blue, .1);
      color: $color-blue;
    }
  }

  .ant-tabs-ink-bar {
    background-color: $color-blue;
  }
}

.without_nav {
    .ant-tabs-nav-container {
        height: 0;
    }
}

.ant-table-thead > tr > th {
  @extend .text;
  @extend .text-14;
  @extend .font-medium;
  @extend .font-uppercase;
  @extend .font-spacing-04;
  @extend .color-grey;
  padding: 6px 24px 14px;
}

.ant-table-tbody > tr > td {
  @extend .text;
  @extend .text-14;
  @extend .font-medium;
  @extend .color-grey-800;
  padding: 20px 24px;
}

.ant-table-tbody > tr > td {
  //border-bottom: 1.4771px dotted #C6C6C6;
}

.ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}

.ant-table-thead > tr > th {
  background: none;
}

.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #F4F6F8;
}

.layout_fixed {
    table {
        table-layout: fixed;
    }
}

.c-table-bordered {
  table {
    border-spacing: 0 16px;
  }

  .ant-table-thead > tr > th {
    border: 0;
    padding-top: 0;
    padding-bottom: 0;

    > .ant-table-header-column {
      height: 15px;
    }
  }

  .ant-table-tbody > tr {
    > td {
      padding-top: 18px;
      padding-bottom: 18px;
      border-top: solid 1px #EEEEEE;
      border-bottom: solid 1px #EEEEEE;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-left: solid 1px #EEEEEE;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-right: solid 1px #EEEEEE;
      }
    }
  }
}

.publish-picker {
  .ant-calendar-today-btn {
    display: none !important;
  }
}

.clickable-rows {
  td {
    cursor: pointer;
  }
}

.c-table-sm {
  .ant-table-tbody > tr > td {
    padding: 4px 24px;
    font-size: 15px;
    @extend .color-grey;
  }
}

.c-table-expand {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.hide_paging {
  .ant-pagination {
    display: none;
  }
}

.paging_right {
    .ant-pagination {
        text-align: right;
    }
}

.hide_head {
    .ant-table-thead {
        display: none;
    }
}

.tab_badge {
  transition: all 0.2s ease;
  background-color: rgba($color-grey-800, .08);
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  margin-left: 8px;
}

.ant-tabs-nav .ant-tabs-tab {
  margin-right: 42px;
}

.ant-breadcrumb a {
  color: $color-grey-800;
}

.c-table-contacts {
  .ant-table table {
    border-spacing: 0 6px;
  }

  .ant-table-thead > tr > th {
    padding-bottom: 0;
    padding-top: 0;
  }

  .ant-table-thead > tr > th {
    border-bottom: 0;
  }

  .ant-table-tbody > tr:not(:last-child) > td {
    border-bottom: 0;
    position: relative;

    &:after {
      content: '';
      background: url('../../img/lines-x.png') no-repeat;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
    }
  }

  .ant-table-tbody > tr.ant-table-row-selected td {
    &:first-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }

    &:after {
      display: none;
    }
  }
}

.c-table-default {
    .ant-table table {
        border-spacing: 0 6px;
    }

    .ant-table-thead > tr > th {
        padding-bottom: 0;
        padding-top: 0;
    }


    .ant-table-thead > tr > th {
        border-bottom: 0;
    }

    .ant-table-tbody > tr.ant-table-row-selected td {
        border-radius: 0;

        &:after {
            display: none;
        }
    }

    .ant-table-tbody > tr > td {
        padding: 15px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
    .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
    .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
        background-color: inherit;
    }
}

.c-table-dashed {
    .ant-table-tbody > tr > td {
        border-bottom: 0;
        position: relative;

        &:after {
            content: '';
            background: url('../../img/lines-x.png') no-repeat;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
        }
    }
}

.ant-table-tbody > tr.ant-table-row-selected {
  td {
    background: rgba($color-blue, 0.1);
    color: $color-blue;
    font-weight: 600;
  }
}

.ant-table-pagination.ant-pagination {
  float: none;
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  background-color: inherit;
  border: 0;

  .anticon {
    font-family: 'icomoon' !important;

    svg {
      display: none;
    }
  }
}

.ant-pagination-next .ant-pagination-item-link {
  .anticon {
    &:after {
      content: "\e925";
    }
  }
}

.ant-pagination-prev .ant-pagination-item-link {
  .anticon {
    &:after {
      content: "\e924";
    }
  }
}

.ant-pagination-item {
  @extend .text;
  @extend .text-14;
  @extend .font-medium;
  @extend .color-grey;
  @extend .radius-circle;
  background-color: inherit;
  line-height: 36px;
  min-width: 36px;
  height: 36px;
  border: 0;
}

.ant-pagination-item-active {
  background: rgba($color-blue, 0.1);
  @extend .font-heavy;

  &:hover {
    a {
      color: inherit;
    }
  }

  a {
    color: inherit;
  }
}

.ant-upload.ant-upload-drag {
  padding: 32px 15px 36px;
  background: rgba(54, 101, 193, 0.02);
  border-color: rgba(54, 101, 193, 0.5);
  border-radius: 10px;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 0;
}

.ant-collapse:not(.collapse-with-arrow) > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow,
.ant-collapse-icon-position-right:not(.collapse-with-arrow) > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: none;
}

.collapse-with-arrow {
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow:before {
    display: block;
  }

  .ant-collapse-arrow {
    @extend %primary-transition;
  }
  
  & > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow {
    @include transform(translateY(-50%) rotate(180deg));
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 30px 0 28px;
}

.ant-collapse:not(.filter_collapse) > .ant-collapse-item {
  border: 0;
  background: url("../../img/lines-x.png") bottom left/auto repeat-x;
}

.ant-collapse-content {
  border: 0;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 22px 0;
}

.ant-collapse {
  border: 0;
  background: inherit;
}

.ant-collapse-item-active {
  .collapse_label,
  .collapse_icon .icon {
    color: $color-blue;
  }

  .collapse_icon .icon {
    &:before {
      content: "\e925";
    }
  }
}

textarea.ant-input {
  min-height: 112px;
}

.radio-group-block {
  .ant-radio-wrapper {
    display: flex;
    margin-bottom: 12px;
  }

  .ant-radio, .ant-radio-wrapper {
    white-space: normal;
  }
}

.ant-calendar-picker {
  display: block;
}

.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:before {
  display: none;
}

.c-table-bordered .ant-table-thead > tr > th > .ant-table-header-column {
  height: inherit;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter {
  background: inherit;
}

.ant-select-selection--multiple .ant-select-selection__rendered > ul > li, .ant-select-selection--multiple > ul > li {
    height: initial;
    line-height: 20px;
}

.ant-select-selection--multiple .ant-select-selection__choice__content {
    white-space: normal;
}

.ant-select {
    &.default_width {
        min-width: 200px;
    }

    &.min_width {
        min-width: 112px;
    }
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-checkbox-wrapper {
  margin: 0 !important;
  margin-bottom: 15px !important;
}

.ant-spin-container {
  height: inherit;
}

.auction-info-table {
  margin: rem(24) 0;

  .ant-table-thead > tr > th {
    padding: 6px 12px 14px;
  }
  .ant-table-tbody > tr > td {
    padding: 16px 12px;

    &:first-child {
      padding-left: 0;
    }
  }
}

@media (max-width: $media-xxxl) {
    .tab_default .ant-tabs-bar {
        margin-bottom: 24px;
    }

    .bids_table {
      &.c-table-bordered table {
        width: 180%;
      }
    }
}

@media (max-width: 1440px) {
    .tab_default .ant-tabs-nav .ant-tabs-tab {
        font-size: 18px;
    }

    .form-item-white .ant-input {
        height: 48px;
    }
}

@media (max-width: $media-xl) {
    .ant-tabs-nav .ant-tabs-tab {
        margin-right: 23px;
    }

    .tab_default .ant-tabs-bar {
        margin-bottom: 14px;
    }

    .tab_default .ant-tabs-nav .ant-tabs-tab {
        padding-top: 0;
    }
}


@media (max-width: $media-lg) {
    .form-item-white .ant-input {
        height: 40px;
    }

    .ant-table-thead {
        display: none;
    }

    .bids_table {
      &.c-table-bordered table {
        width: 100%;
      }

      .ant-table-tbody > tr > td {
        &:nth-child(10) {
          order: 7;
          text-align: right;

          .btn {
            margin-top: 10px;
          }
        }
      }
    }

    .dashboard_contact {
        .ant-table-tbody > tr {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            padding: 22px 15px 28px;

            //&:not(:last-child) {
            //    background: url("../../img/lines-x.png") bottom left/auto repeat-x;
            //}
        }

        .ant-table table, .ant-table table tbody {
            display: block;
        }

        &.c-table-contacts .ant-table-tbody > tr:not(:last-child) > td:after {
            background-size: 0;
        }

        .ant-table-tbody > tr > td {
            padding: 0;
            display: block;
            height: 100%;

            &:nth-child(1) {
                order: 1;
                max-width: 70%;
                flex: 0 0 70%;
                margin-bottom: 17px;
            }

            &:nth-child(2) {
                order: 2;
                max-width: 30%;
                flex: 0 0 30%;
                text-align: right;
                margin-bottom: 17px;
            }

            &:nth-child(3) {
                order: 3;
                max-width: 100%;
                flex: 0 0 100%;
                margin-bottom: 13px;
            }

            &:nth-child(4) {
                order: 6;
                max-width: 30%;
                flex: 0 0 30%;

                .imp_tag {
                    display: table;
                    margin-left: auto;
                }
            }

            &:nth-child(5) {
                order: 5;
                max-width: 70%;
                flex: 0 0 70%;
            }

            &:nth-child(6) {
                order: 4;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 100%;
                flex: 0 0 100%;
                margin-bottom: 16px;
            }
        }
    }

    .ant-table-tbody > tr {
        &:not(:last-child) {
            background: url("../../img/lines-x.png") bottom left/auto repeat-x;
        }
    }

    .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected),
    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected),
    .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected),
    .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
        background: #F4F6F8;
        border-radius: 8px;

        > td {
            background: initial;
        }
    }

    .ant-table-tbody > tr.unread-row:not(.ant-table-row-selected) {
        background: #F4F6F8;
        margin-bottom: 6px;
        border-radius: 8px;

        td {
            background: initial;
            border-radius: 0;
        }
    }

    .ant-table-tbody > tr.ant-table-row-selected,
    .ant-table-tbody > tr:hover.ant-table-row-selected,
    .ant-table-tbody > tr:hover.ant-table-row-selectedt,
    .ant-table-thead > tr.ant-table-row-selected,
    .ant-table-thead > tr:hover.ant-table-row-selected,
    .ant-table-thead > tr:hover.ant-table-row-selected {
        background: #F4F6F8;
        border-radius: 8px;
        margin-bottom: 6px;

        > td.ant-table-column-sort,
        > td {
            background: initial;
        }
    }

    .dashboard_data {
        .ant-table table, .ant-table table tbody {
            display: block;
        }

        .ant-table-tbody > tr {
            border: 1px solid #EEEEEE;
            border-radius: 8px;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            padding: 16px 14px 17px;

            &:not(:last-child) {
                background: initial;
                margin-bottom: 15px;
            }
        }

        &.c-table-bordered .ant-table-tbody > tr > td {
            border:  0;
            padding: 0;

            &:nth-child(1) {
                order: 1;
                max-width: 70%;
                flex: 0 0 70%;
            }

            &:nth-child(2) {
                display: none;
            }

            &:nth-child(3) {
                order: 3;
                max-width: 60%;
                flex: 0 0 60%;
            }

            &:nth-child(4) {
                order: 2;
                max-width: 30%;
                flex: 0 0 30%;
                font-size: 8px;
                text-align: right;
                margin-bottom: 17px;
            }

            &:nth-child(5) {
                order: 4;
                max-width: 40%;
                flex: 0 0 40%;
                text-align: right;
                font-size: 10px;
            }
        }
    }

    .contact_table {
        .ant-table-tbody > tr:not(:last-child) > td:after {
            display: none;
        }

        .ant-table-tbody > tr {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            position: relative;
            padding: 18px 15px 20px;
        }

        .ant-table table, .ant-table table tbody {
            display: block;
        }

        &.c-table-contacts .ant-table-tbody > tr:not(:last-child) > td:after {
            background-size: 0;
        }

        .ant-table-tbody > tr > td {
            padding: 0;
            display: block;

            //&:nth-child(1) {
            //    position: absolute;
            //    top: 23px;
            //    left: -25px;
            //
            //    .ant-checkbox-wrapper {
            //        line-height: 1;
            //    }
            //}

          &:nth-child(1) {
            order: 1;
            max-width: 70%;
            flex: 0 0 70%;
            margin-bottom: 17px;
          }

            &:nth-child(2) {
                order: 2;
                max-width: 30%;
                flex: 0 0 30%;
                margin-bottom: 17px;
            }

            &:nth-child(3) {
                order: 3;
                max-width: 100%;
                flex: 0 0 100%;
                font-size: 10px;
                //text-align: right;
                margin-bottom: 17px;
            }

            &:nth-child(4) {
                order: 4;
                max-width: 100%;
                flex: 0 0 100%;
                font-weight: 600;
                color: $color-grey;
                margin-bottom: 15px;
            }

            &:nth-child(5) {
                order: 7;
                max-width: 30%;
                flex: 0 0 30%;
              text-align: right;

                .imp_tag {
                    display: table;
                    margin-left: auto;
                }
            }

            &:nth-child(6) {
                order: 6;
                max-width: 70%;
                flex: 0 0 70%;
            }

            &:nth-child(7) {
                order: 5;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 100%;
                flex: 0 0 100%;
                margin-bottom: 16px;
            }
        }

        &_sent {
            .ant-table-tbody > tr > td {
                &:nth-child(5) {
                    order: 5;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 100%;
                    flex: 0 0 100%;
                    margin-bottom: 16px;
                }
            }
        }
    }

    // from ui branch
    //.contact_table {
    //    .ant-table-tbody > tr:not(:last-child) > td:after {
    //        display: none;
    //    }
    //    .ant-table-tbody > tr {
    //        display: flex;
    //        flex-flow: row wrap;
    //        align-items: center;
    //        position: relative;
    //        padding: 18px 15px 20px;
    //    }
    //    .ant-table table, .ant-table table tbody {
    //        display: block;
    //    }
    //    &.c-table-contacts .ant-table-tbody > tr:not(:last-child) > td:after {
    //        background-size: 0;
    //    }
    //    .ant-table-tbody > tr > td {
    //        padding: 0;
    //        display: block;
    //        //&:nth-child(1) {
    //        //    position: absolute;
    //        //    top: 23px;
    //        //    left: -25px;
    //        //
    //        //    .ant-checkbox-wrapper {
    //        //        line-height: 1;
    //        //    }
    //        //}
    //        &:nth-child(1) {
    //            order: 1;
    //            max-width: 70%;
    //            flex: 0 0 70%;
    //            margin-bottom: 17px;
    //        }
    //        &:nth-child(2) {
    //            order: 2;
    //            max-width: 30%;
    //            flex: 0 0 30%;
    //            margin-bottom: 17px;
    //        }
    //        &:nth-child(3) {
    //            order: 3;
    //            max-width: 100%;
    //            flex: 0 0 100%;
    //            font-size: 10px;
    //            //text-align: right;
    //            margin-bottom: 17px;
    //        }
    //        &:nth-child(4) {
    //            order: 4;
    //            max-width: 100%;
    //            flex: 0 0 100%;
    //            font-weight: 600;
    //            color: $color-grey;
    //            margin-bottom: 15px;
    //        }
    //        &:nth-child(5) {
    //            order: 7;
    //            max-width: 30%;
    //            flex: 0 0 30%;
    //            text-align: right;
    //            .imp_tag {
    //                display: table;
    //                margin-left: auto;
    //            }
    //        }
    //        &:nth-child(6) {
    //            order: 6;
    //            max-width: 70%;
    //            flex: 0 0 70%;
    //        }
    //        &:nth-child(7) {
    //            order: 5;
    //            white-space: nowrap;
    //            text-overflow: ellipsis;
    //            overflow: hidden;
    //            max-width: 100%;
    //            flex: 0 0 100%;
    //            margin-bottom: 16px;
    //        }
    //    }
    //    &_sent {
    //        .ant-table-tbody > tr > td {
    //            &:nth-child(5) {
    //                order: 5;
    //                white-space: nowrap;
    //                text-overflow: ellipsis;
    //                overflow: hidden;
    //                max-width: 100%;
    //                flex: 0 0 100%;
    //                margin-bottom: 16px;
    //            }
    //        }
    //    }
    //}

    .users_table {
        .ant-table table, .ant-table table tbody {
            display: block;
        }

        .ant-table-tbody > tr {
            border: 1px solid #EEEEEE;
            border-radius: 8px;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            padding: 16px 14px 17px;

            &:not(:last-child) {
                background: initial;
                margin-bottom: 15px;
            }
        }

        &.c-table-bordered .ant-table-tbody > tr > td {
            border:  0;
            padding: 0;

            &:nth-child(1) {
                order: 1;
                max-width: 66%;
                flex: 0 0 66%;
                margin-bottom: 17px;
            }

            &:nth-child(2) {
                order: 3;
                max-width: 70%;
                flex: 0 0 70%;
                margin-bottom: 14px;
            }

            &:nth-child(3) {
                order: 4;
                max-width: 30%;
                flex: 0 0 30%;
                font-size: 11px;
                text-align: right;
                margin-bottom: 14px;
            }

            &:nth-child(4) {
                order: 5;
                max-width: 50%;
                flex: 0 0 50%;
                font-size: 12px;
            }

            &:nth-child(5) {
                order: 6;
                max-width: 50%;
                flex: 0 0 50%;
                text-align: right;
                font-size: 12px;
            }

            &:nth-child(6) {
                order: 2;
                max-width: 34%;
                flex: 0 0 34%;
                text-align: right;
                margin-bottom: 17px;

                .icon_btn_group {
                    justify-content: flex-end;

                    .icon_btn {
                        &:not(:last-child) {
                            margin-right: 8px;
                        }
                    }
                }

                .icon_btn .btn {
                    width: 32px;
                    height: 32px;
                    line-height: 32px;
                    padding: 0 2px;

                    .icon {
                        font-size: 14px !important;
                    }
                }
            }
        }

        &.allow_row_selection .ant-table-tbody > tr > td {
            &:nth-child(1) {
                text-align: left;
            }
        }
    }
    
    .requests_table.users_table {
        &.c-table-bordered .ant-table-tbody > tr > td {
            &:nth-child(1) {
              max-width: 100%;
              flex: 0 0 100%;
            }

            &:nth-child(2) {
              order: 2;
            }

            &:nth-child(3) {
              order: 3;
            }

            &:nth-child(4) {
              order: 4;
            }

            &:nth-child(5) {
              order: 5;
            }

            &:nth-child(6) {
              order: 6;
              max-width: 100%;
              flex: 0 0 100%;
              margin-bottom: 0;
              margin-top: 24px;

              .btn_group .btn {
                height: 40px;
                line-height: 40px;
                
                &:not(:last-child) {
                  margin-right: 16px;
                }
              }
            }
        }
    }

    .stretch_table {
        .ant-table table, .ant-table table tbody {
            display: block;
        }

        .ant-table-tbody > tr {
            border: 1px solid #EEEEEE;
            border-radius: 8px;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            padding: 16px 14px 17px;

            &:not(:last-child) {
                background: initial;
                margin-bottom: 15px;
            }
        }

        &.c-table-bordered .ant-table-tbody > tr > td {
            border: 0;
            padding: 0;
            margin-bottom: 15px;

            &:nth-child(1) {
                order: 1;
                max-width: 83%;
                flex: 0 0 83%;
            }

            @for $i from 2 through 17 {
                &:nth-child(#{$i}) {
                    order: $i+1;
                    max-width: 50%;
                    flex: 0 0 50%;
                }
            }

            &:last-child {
                order: 2;
                max-width: 17%;
                flex: 0 0 17%;

                > div {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }

    .ant-table-tbody > tr > td {
        //padding: 5px 15px;
    }

    .ant-breadcrumb {
        margin-bottom: 20px;
    }
}

@media (max-width: $media-sm) {
    .radio-template {
        max-width: 100%;
        margin-bottom: 15px;
    }

    .radio-template > .ant-radio-wrapper {
        margin: 0;
        height: 40px;
    }

    .form-item-label {
        font-size: 12px;
        line-height: 18px;
        font-weight: bold;
    }

    .form-item-hint {
        font-size: 10px;
    }

    .ant-form label {
        letter-spacing: 0.04em;
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
    }

    .ant-input,
    .ant-select-selection--single {
        height: 40px;
    }

    .form-item-white .ant-select-selection--multiple {
        min-height: 40px;
    }

    .ant-select-selection__rendered {
        line-height: 40px;
    }

    .tab_badge {
        width: 22px;
        height: 22px;
        line-height: 22px;
        font-size: 10px;
    }

    .contact_table .ant-table-tbody > tr > td:nth-child(1) {
        display: none;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 20px 0;
    }

    .c-table-expand {
        .ant-table-body {
            overflow: auto;
        }
    }

    .ant-pagination-item {
        min-width: 32px;
        height: 32px;
        line-height: 32px;
    }

    .ant-checkbox-wrapper {
        font-size: 12px;
    }
}
